<template>
  <article class="create-component create">
    <headTitle :name="'todos'" :title="title"></headTitle>
    <form action="#" @submit.prevent="createItem">
      <div class="form-group" v-for="field in fields" :key="field.name">
        <input type="text" class="form-control" :ref="`${field.name}`" :placeholder="field.placeholder" />
      </div>
      <button type="submit" class="btn btn-outline-secondary">ساخت</button>
    </form>
    <err v-if="showErr" :message="message"></err>
  </article>
</template>

<script>
// [text , subject]
export default {
  props: ["url", "fields", "title", "datesName", "datesValue", "redirect"],
  data() {
    return {
      message: "",
      showErr: false,
    };
  },
  methods: {
    createItem() {
      let elements = [];
      let values = [];
      for (let index = 0; index < this.fields.length; index++) {
        let element = this.fields[index].name;
        let value = this.$refs[element][0].value;
        values.push(value);
        elements.push(element);
      }
      let data = {
        token: localStorage.getItem("token"),
      };
      for (let index = 0; index < elements.length; index++) {
        data[elements[index]] = values[index];
      }
      for (let index = 0; index < this.datesName.length; index++) {
        data[this.datesName[index]] = this.datesValue[index];
      }
      this.$http
        .post(this.url, data)
        .then((response) => {
          this.showErr = false;
          console.log(response);
          this.$router.push({ name: this.redirect });
        })
        .catch((err) => {
          console.log(err);
          this.showErr = true;
          this.message = err.response.data.message;
        });
    },
  },
};
</script>
