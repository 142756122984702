/* eslint-disable no-undef */
import axios from "axios";

const localUrl = "http://localhost/dashboard2/auth/";
const siteUrl = "https://amirmohammadgharibi.ir/api/dashboard/";

export default {
  methods: {
    toEnglishNumber(number) {
      var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
      var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      var cache = number;
      for (var i = 0; i < 10; i++) {
        var regex_fa = new RegExp(pn[i], "g");
        cache = cache.replace(regex_fa, en[i]);
      }
      return cache;
    },
    monthChanger(month) {
      if (month == 1) {
        month = "فروردین";
      }
      if (month == 2) {
        month = "اردیبهشت";
      }
      if (month == 3) {
        month = "خرداد";
      }
      if (month == 4) {
        month = "تیر";
      }
      if (month == 5) {
        month = "مرداد";
      }
      if (month == 6) {
        month = "شهریور";
      }
      if (month == 7) {
        month = "مهر";
      }
      if (month == 8) {
        month = "آبان";
      }
      if (month == 9) {
        month = "آذر";
      }
      if (month == 10) {
        month = "دی";
      }
      if (month == 11) {
        month = "بهمن";
      }
      if (month == 12) {
        month = "اسفند";
      }
      return month;
    },
    splitNumber(number) {
      let x, y, z;
      number += "";
      number = number.replace(",", "");
      x = number.split(".");
      y = x[0];
      z = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(y)) y = y.replace(rgx, "$1" + "," + "$2");
      return y + z;
    },
    nameToUrl(name) {
      let checkName = name.split(" ");
      if (checkName.length > 1) {
        let newName = checkName.join("-"); // اصول فناوری   اصول-قناوری
        return newName;
      } else {
        return name;
      }
    },
    nameToShow(name) {
      let arr = name.split("-");
      if (arr.length > 1) {
        let newName = arr.join(" "); // اصول-فناوری  اصول فناوری
        return newName;
      } else {
        return name;
      }
    },
  },
};
