import Vue from "vue";

import App from "./App.vue";

Vue.config.productionTip = false;

//- tools -//
import VueRouter from "vue-router";
import axios from "axios";
import Toast from "vue-toastification";
import loader from "vue-ui-preloader";

//- End tools -//

//- import my creation -//
import { Routes } from "./routes";
import { store } from "./store";
//- End import my creation -//

//- vue-router config -//
const router = new VueRouter({
  routes: Routes,
  mode: "history",
});
//- End vue-router config -//

// Require Froala Editor js file.
require("froala-editor/js/froala_editor.pkgd.min.js");

// Require Froala Editor css files.
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/css/froala_style.min.css");

import VueFroala from "vue-froala-wysiwyg";
Vue.use(VueFroala, { language: "fa" });

//- css section -//
import "../public/css/bootstrap.min.css";
import "../public/css/font-awesome.min.css";
import "animate.css";
import "vue-toastification/dist/index.css";
import "../public/css/style.css";
import "../public/css/media.css";
//- End css section -//

//- components -//
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
Vue.use(VuePersianDatetimePicker, {
  name: "date-picker",
  props: {
    // format: "YYYY-MM-DD HH:mm",
    // displayFormat: "jYYYY/jMM/jDD",
    editable: false,
    inputClass: "form-control noBorder",
    placeholder: "تاریخ خود را انتخاب کنید",
    altFormat: "YYYY-MM-DD",
    color: "#1A1A1A",
    autoSubmit: true,
  },
});

import err from "./components/layouts/Error.vue";
Vue.component("err", err);

import header from "./components/layouts/Header.vue";
Vue.component("headTitle", header);

import createComponent from "./components/layouts/Create.vue";
Vue.component("create-component", createComponent);

// import breadcrumb from "./components/layouts/Breadcrumb.vue";
// Vue.component("breadcrumb", breadcrumb);

import PulseLoader from "vue-spinner/src/PulseLoader.vue";
Vue.component("PulseLoader", PulseLoader);

//- End components -//

//- use -//
Vue.use(VueRouter);
Vue.use(Toast, {
  timeout: 2000,
});

Vue.prototype.$http = axios;
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "http://localhost:3000/";
} else {
  axios.defaults.baseURL = "https://api.dashboard.amirmohammadgharibi.ir/";
}

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

Vue.use(loader);
//- End use -//

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
