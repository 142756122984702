<template>
  <div class="alert alert-secondary mt-3">
    <h6>
      {{ message }}
    </h6>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>

<style scoped>
h6 {
  direction: rtl;
  content: "a";
  line-height: 25px;
}
</style>
