<template>
  <div class="headDiv">
    <h3>
      {{ title }}
    </h3>
    <button v-if="show == undefined">
      <router-link :to="{ name: `${name}`, params: { params } }" v-if="params != undefined">
        <i class="fa-solid fa-arrow-left returnIcon"> </i>
      </router-link>
      <router-link :to="{ name: `${name}`, query: { query } }" v-if="query != undefined">
        <i class="fa-solid fa-arrow-left returnIcon"> </i>
      </router-link>
      <router-link :to="{ name: `${name}` }" v-if="params == undefined && query == undefined">
        <i class="fa-solid fa-arrow-left returnIcon"> </i>
      </router-link>
    </button>
  </div>
</template>

<script>
export default {
  props: ["name", "title", "show", "params", "query"],
};
</script>
