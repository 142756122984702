<template>
  <aside>
    <div class="general-status">
      <p class="m-0 date-desktop">تاریخ امروز : {{ `${$store.state.currentDate} ${monthChanger($store.state.currentMonth)}` }}</p>
      <div class="date-mobile w-100">
        <i class="fas fa-smile"></i>
      </div>
    </div>
    <ul>
      <li class="list-item">
        <router-link :to="{ name: 'home' }" class="list-item-link" active-class="active" exact title="خانه">
          <i class="fa fa-home"></i> <span>خانه</span>
        </router-link>
      </li>
      <li class="list-item">
        <router-link :to="{ name: 'todos' }" class="list-item-link" active-class="active" title="کار های روزانه">
          <i class="fas fa-edit"></i> <span>کار روزانه</span>
        </router-link>
      </li>
      <li class="list-item">
        <router-link :to="{ name: 'texts' }" class="list-item-link" active-class="active" title="نوشته های ماهانه">
          <i class="fa fa-file-text"></i><span>نوشته</span>
        </router-link>
      </li>
      <li class="list-item" v-if="user.university == true">
        <router-link :to="{ name: 'university' }" class="list-item-link" active-class="active" title="دانشگاه">
          <i class="fas fa-university"></i><span>دانشگاه</span>
          <!-- <i class="fa-solid fa-graduation-cap"></i><span>دانشگاه</span> -->
        </router-link>
      </li>
      <li class="list-item" v-if="user.university == true">
        <router-link :to="{ name: 'exams' }" class="list-item-link" active-class="active" title="امتحان ها">
          <i class="fas fa-book-dead"></i><span>امتحان</span>
        </router-link>
      </li>
      <li class="list-item">
        <router-link :to="{ name: 'financial' }" class="list-item-link" active-class="active" title="امور مالی">
          <i class="fas fa-money-bill"></i><span>امور مالی</span>
        </router-link>
      </li>
      <li class="list-item">
        <router-link :to="{ name: 'books' }" class="list-item-link" active-class="active" title="کتاب ها">
          <i class="fas fa-book"></i><span>کتاب</span>
        </router-link>
      </li>
      <li class="list-item">
        <router-link :to="{ name: 'bookmarks' }" class="list-item-link" active-class="active" title="بوک مارک ها">
          <i class="fas fa-bookmark"></i><span>بوک مارک</span>
        </router-link>
      </li>
      <li class="list-item">
        <router-link :to="{ name: 'ideas' }" class="list-item-link" active-class="active" title="ایده ها">
          <i class="fa-solid fa-lightbulb"></i><span>ایده</span>
        </router-link>
      </li>
      <li class="list-item">
        <router-link :to="{ name: 'messages' }" class="list-item-link" active-class="active" title="پیام ها">
          <i class="fa-solid fa-message"></i><span>پیام</span>
        </router-link>
      </li>
      <!-- <li class="list-item">
        <router-link :to="{ name: 'learn' }" class="list-item-link" active-class="active" title="آموزش">
          <i class="fa-solid fa-graduation-cap"></i><span>آموزش</span>
        </router-link>
      </li> -->
      <li class="list-item">
        <router-link :to="{ name: 'setting' }" class="list-item-link" active-class="active" title="تنظیمات">
          <i class="fas fa-cog"></i><span>تنظیمات</span>
        </router-link>
      </li>
      <li class="list-item" v-if="user.is_admin == true">
        <router-link :to="{ name: 'admin' }" class="list-item-link" active-class="active" title="ادمین">
          <i class="fa fa-user-secret"></i><span>پنل ادمین</span>
        </router-link>
      </li>
      <li class="list-item" v-on:click="logOut">
        <a class="list-item-link" title="خارج شو"> <i class="fas fa-power-off text-danger"></i><span>خروج</span> </a>
      </li>
    </ul>
  </aside>
</template>
<script>
import mixing from "../../mixing";
export default {
  mixins: [mixing],
  props: ["user"],
  data() {
    return {
      menuItems: [],
      addShow: false,
      showHelp: false,
      admin: false,
    };
  },
  methods: {
    logOut() {
      this.$http
        .post("logout", { token: localStorage.getItem("token") })
        .then((response) => {
          localStorage.clear();
          this.$toast.success("به سلامت منتظرت هستیم", {
            icon: {
              iconClass: "fas fa-hand-paper",
            },
            timeout: 3000,
          });
          this.$router.push("/");
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.error(err.response.data.message);
          }
        });
    },
    date() {
      return `${this.$store.state.currentDate} ${mixing.methods.monthChanger(this.$store.state.currentMonth)}`;
    },
  },
};
</script>
